<template>
    <div></div>
</template>
<script setup>
import axios from "axios";
import { useStorage } from "vue3-storage";
import { useRoute } from 'vue-router'

const route = useRoute()
const storage = useStorage()
if (storage.getStorageSync('token')) {
    authenticateZandesk()
}else{
    window.location.href = process.env.VUE_APP_URL;
}

async function authenticateZandesk(){
    await axios.get(process.env.VUE_APP_BASE_URL+'authenticate-zendesk', {
        params: route.query,
        headers: {Authorization:'Bearer ' + storage.getStorageSync('token')}
    }).then(function (response) {
        if (response.data.status == 'success') {
            window.location.href = response.data.data;
        }
    })
    .catch(error => {
        if (error.response) {
            if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                window.location.href = process.env.VUE_APP_URL;
            }
        }
    });
}
</script>